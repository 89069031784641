import React from 'react';
import PropTypes from 'prop-types';

import Container from './LegalText.style';


const LegalText = props => (
  <Container>{props.children}</Container>
);

LegalText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default LegalText;
