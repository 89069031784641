import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../atoms/Button';
import LegalText from '../../atoms/LegalText/LegalText';
import CalculationResult from '../../molecules/CalculationResult/CalculationResult';
import RangeField from '../../molecules/RangeField/RangeField';
import buildTwoThirdLegalTextString from '../../util/buildTwoThirdLegalTextString';
import { calculateCreditAmount, calculateMonthlyPayment, calculateTotal } from '../../util/calculateRates';
import deepDefaultProps from '../../util/deepDefaultProps';
import { buildTargetUrl, getBestOfferByMonthlyRate, mapPurposeToCafePurpose } from '../../util/formHelper';
import { getSessionStorage } from '../../util/session/sessionHandler';
import { Flex, Form } from './DesiredRateForm.style';


/**
 * @type {String}
 */
export const STORAGE_KEY_PREFIX = 'fc-rate-';

/**
 * @type {String}
 */
export const STORAGE_KEY_RESUME_HASH = STORAGE_KEY_PREFIX + 'resumeHash';

/**
 * @type {Number} - Milliseconds to throttle best offer update events
 */
const DEBOUNCE_BEST_OFFER_UPDATE = 0; // currently 0 because of local result caching of PAAS offers

class DesiredRateForm extends React.PureComponent {

  static propTypes = {
    affiliateData: PropTypes.object.isRequired,
    submitUrl: PropTypes.instanceOf(URL).isRequired,
    minRate: PropTypes.number,
    maxRate: PropTypes.number,
    minTerm: PropTypes.number,
    maxTerm: PropTypes.number,
    resolveToUrl: PropTypes.func.isRequired,
    resolveWithLoader: PropTypes.func.isRequired,
  };

  static defaultProps = {
    minRate: 100,
    maxRate: 1000,
    minTerm: 12,
    maxTerm: 120,
  };

  constructor(props) {
    super(props);

    const {
      affiliateData: {
        rate,
        term,
      },
    } = this.props;

    const initialAmount = rate * term;

    this.state = {
      rate,
      term,
      monthlyRateTwoThird: rate,
      effectiveInterestRateTwoThird: 0,
      bank: '',
      netAmount: initialAmount,
      netAmountTwoThird: initialAmount,
      totalAmountTwoThird: initialAmount,
    };

    this.updateBestOffer();
  }

  updateBestOffer = async () => {
    const {
      props: {
        affiliateData: {
          advertisementId,
          purpose,
        },
        resolveWithLoader,
      },
      state: {
        rate,
        term,
      },
    } = this;

    const bestOffer = await resolveWithLoader(getBestOfferByMonthlyRate({ rate, term, purpose }, advertisementId));
    if (!bestOffer) return;

    const {
      effectiveInterestRate,
      effectiveInterestRateTwoThird,
      nominalInterestRateTwoThird,
      bank,
    } = bestOffer;

    const netAmount = calculateCreditAmount({
      rate,
      term,
      effectiveInterestRate,
    });

    const netAmountTwoThird = calculateCreditAmount({
      rate,
      term,
      effectiveInterestRate: effectiveInterestRateTwoThird,
    });

    const totalAmountTwoThird = calculateTotal({
      amount: netAmountTwoThird,
      term,
      effectiveInterest: effectiveInterestRateTwoThird / 100,
    });

    const monthlyRateTwoThird = calculateMonthlyPayment({
      amount: netAmountTwoThird,
      term,
      effectiveInterest: effectiveInterestRateTwoThird / 100,
    });

    this.setState({
      netAmount,
      netAmountTwoThird,
      totalAmountTwoThird,
      monthlyRateTwoThird,
      effectiveInterestRateTwoThird,
      nominalInterestRateTwoThird,
      bank: bank.name,
    });
  };

  /**
   * @param {Event} event
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    if (!form.checkValidity()) return;

    const {
      props: {
        affiliateData: {
          advertisementId,
          subId,
          targetUrl,
          purpose,
          parameters,
          submitMode,
        },
      },
      state: {
        netAmount,
        term,
      },
    } = this;


    if (targetUrl !== undefined) {
      const url = buildTargetUrl(targetUrl, {
        ...parameters,
        advertisementId,
        amount: netAmount,
        purpose: mapPurposeToCafePurpose(purpose),
        subId,
        term,
      });
      switch (submitMode) {
        default:
        case 'REDIRECT':
          window.location.href = url;
          break;
        case 'WINDOW':
          window.open(url, '_blank');
          break;
        case 'AMP':
          window.open(url, '_top');
          break;
      }
      return;
    }

    // TODO ... two different session storage handlers?!
    const resumeHash = sessionStorage.getItem(STORAGE_KEY_RESUME_HASH);
    const { bid } = getSessionStorage();

    const data = {
      ...parameters,
      amount: netAmount,
      term,
      purpose: 'OTHER',
      advertisementId,
      subId,
      browserId: bid,
    };

    if (resumeHash) {
      data.resumeHash = resumeHash;
    }

    const request = new Request(form.action, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
      }),
      body: JSON.stringify(data),
    });

    const promise = fetch(request)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Server responded with status ${response.status}`);
        }

        if (!response.headers.get('Content-Type').match(/^application\/json\b/)) {
          throw new Error(`Bad content type: ${response.headers.get('Content-Type')}`);
        }

        return response
          .json()
          .then(({ result }) => {
            sessionStorage.setItem(STORAGE_KEY_RESUME_HASH, result.resumeHash);
            return {
              result,
              request: {
                amount: data.amount,
                term: data.term,
              },
            };
          });
      });

    // resolve promise with loading indicator and open the resulting cafe url
    const { resolveToUrl, resolveWithLoader } = this.props;
    resolveToUrl(resolveWithLoader(promise));
  };

  handleRateChange = (event, value) => {
    this.setState({ rate: value }, debounce(this.updateBestOffer, DEBOUNCE_BEST_OFFER_UPDATE));
  };

  handleTermChange = (event, value) => {
    this.setState({ term: value }, debounce(this.updateBestOffer, DEBOUNCE_BEST_OFFER_UPDATE));
  };

  render() {
    const {
      props: {
        affiliateData: {
          buttonText,
        },
        submitUrl,
        minRate,
        maxRate,
        minTerm,
        maxTerm,
      },
      state: {
        rate,
        term,
        monthlyRateTwoThird,
        netAmount,
        netAmountTwoThird,
        totalAmountTwoThird,
        effectiveInterestRateTwoThird,
        nominalInterestRateTwoThird,
        bank,
      },
    } = this;

    return (
      <Form action={submitUrl} method="post" onSubmit={this.handleSubmit}>
        <Flex>
          <RangeField
            name="desiredRate"
            label="Monatliche Wunschrate"
            min={minRate}
            max={maxRate}
            step={50}
            value={rate}
            unit="€"
            onChange={this.handleRateChange}
          />
          <RangeField
            name="desiredTerm"
            label="Laufzeit"
            min={minTerm}
            max={maxTerm}
            value={term}
            step={12}
            unit="Monate"
            onChange={this.handleTermChange}
          />
          <CalculationResult
            label="Möglicher Kreditwunsch"
            value={netAmount}
            name="calculationResult"
            unit="€"
            hint="Nettodarlehensbetrag*"
          />
          <Button type="submit" label={buttonText} />
        </Flex>

        <LegalText>
          <p>
            {buildTwoThirdLegalTextString({
              netAmountTwoThird,
              totalAmountTwoThird,
              nominalInterestRateTwoThird,
              effectiveInterestRateTwoThird,
              monthlyRateTwoThird,
              bankName: bank,
              term,
            })}
          </p>
        </LegalText>

      </Form>
    );
  }

}

export default deepDefaultProps({
  affiliateData: {
    buttonText: 'Kredite vergleichen',
    // TODO: why the default of '0' here?
    subId: '0',
    rate: 550,
    term: 60,
    purpose: 'OTHER',
  },
})(DesiredRateForm);
