import Styled from 'styled-components';


// TODO: find out how to import from file
// import ThumbHandleIcon from '../../assets/icons/thumb-handle.svg';
export const ThumbHandleIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#dddddd">
    <rect x="7" y="8" height="8" width="2"/>
    <rect x="11" y="8" height="8" width="2"/>
    <rect x="15" y="8" height="8" width="2"/>
  </svg>
`;

// TODO check if this can be imported in a generic way
export const Field = Styled.div`
  display: block;
  position: relative;
  margin-bottom: 1.25em;
`;

export const Frame = Styled.div``;

export const Label = Styled.label`
  float: left;
  font-family: ${props => props.theme.fontFamily};
  font-size: 1em;
  line-height: 1.5em;
  color: ${props => props.theme.labelColor};
`;

export const Output = Styled.output`
  float: right;
  margin-bottom: .75em;
  font-family: ${props => props.theme.fontFamily};
  font-size: 1em;
  line-height: 1.5em;
  color: ${props => props.theme.labelColor};
`;

export const Input = Styled.input`
  -webkit-appearance: none;
  display: block;
  margin: 0;
  width: 100%;
  clear: both;
  background: transparent;

  /* --- track --- */
  &::-webkit-slider-runnable-track {
    margin:
      calc(${props => props.theme.thumbSize} / 2)
      0
    ;
    width: 100%;
    height: ${props => props.theme.trackHeight};
    cursor: pointer;
    background: ${props => props.theme.progressColor};
    border-radius: ${props => props.theme.trackBorderRadius};
  }
  &::-moz-range-track {
    width: 100%;
    height: ${props => props.theme.trackHeight};
    background: ${props => props.theme.trackColor};
    border-radius: ${props => props.theme.trackBorderRadius};
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    height: ${props => props.theme.trackHeight};
    color: transparent;
    background: transparent;
    border-color: transparent;
    border-radius: ${props => props.theme.trackBorderRadius};
    cursor: pointer;
  }
  &::-ms-fill-upper {
    background: ${props => props.theme.trackColor};
  }

  /* --- progress --- */
  &::-moz-range-progress {
    height: ${props => props.theme.trackHeight};
    background: ${props => props.theme.progressColor};
    border-radius: ${props => props.theme.trackBorderRadius};
  }
  &::-ms-fill-lower {
    height: ${props => props.theme.trackHeight};
    background: ${props => props.theme.progressColor};
    border-radius: ${props => props.theme.trackBorderRadius};
  }


  /* --- thumb --- */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: calc((${props => props.theme.thumbSize} - ${props => props.theme.trackHeight}) * -0.5);
    height: ${props => props.theme.thumbSize};
    width: ${props => props.theme.thumbSize};
    background: ${props => props.theme.thumbBackground};
    border: ${props => props.theme.thumbBorderWidth} solid ${props => props.theme.borderColor};
    border-radius: ${props => props.theme.thumbBorderRadius};
    box-sizing: border-box;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    height: ${props => props.theme.thumbSize};
    width: ${props => props.theme.thumbSize};
    background: ${props => props.theme.thumbBackground};
    border: ${props => props.theme.thumbBorderWidth} solid ${props => props.theme.borderColor};
    border-radius: ${props => props.theme.thumbBorderRadius};
    box-sizing: border-box;
    cursor: pointer;
  }
  &::-ms-thumb {
    height: ${props => props.theme.thumbSize};
    width: ${props => props.theme.thumbSize};
    background: ${props => props.theme.thumbBackground};
    border: ${props => props.theme.thumbBorderWidth} solid ${props => props.theme.borderColor};
    border-radius: ${props => props.theme.thumbBorderRadius};
    box-sizing: border-box;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export default Field;
