import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import FieldError from '../../atoms/FieldError/FieldError';
import FieldHint from '../../atoms/FieldHint/FieldHint';
import { formatAmount } from '../../util/numberFormatter';
import { randomHash } from '../../util/random';
import { Field, Frame, Input, Label, Output, ThumbHandleIcon } from './RangeField.style';


/**
 * Cleans up and converts the value of this field to correct type.
 * This method must always be used to store the value within the state
 * and for attaching to external callbacks
 *
 * @param {string} value
 * @return {number}
 */
export const cleanValue = value => parseFloat(value);


class RangeField extends React.PureComponent {

  /**
   * @type {Object}
   */
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    unit: PropTypes.string,
    hint: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.number.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  };

  /**
   * @type {Object}
   */
  static defaultProps = {
    id: '',
    hint: '',
    unit: '',
    step: 1,
    required: false,
    disabled: false,
    onChange: () => { },
    onBlur: () => { },
  };

  /**
   * @constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    const {
      value,
      min,
      max,
      disabled,
      required,
      id,
    } = this.props;

    if (typeof min !== 'number') throw new Error('"min" must be specified and of type number!');
    if (typeof max !== 'number') throw new Error('"max" must be specified and of type number!');
    if (typeof value !== 'number') throw new Error('"value" must be specified and of type number!');
    if (min >= max) throw new Error('"min" must be less than "max"');
    if (value < min || value > max) throw new Error('"value" is out of range');

    this.state = {
      value,
      // TODO isDirty: false,
      isDisabled: disabled,
      isRequired: required,
      isValid: undefined,
      // TODO hasFocus: false,
      wasTouched: false,
      error: '',
    };

    /**
     * @type {String}
     */
    this.id = id || randomHash();

    /**
     * @type {HTMLInputElement}
     */
    this.input = null;
  }

  /**
   * Sets focus to this input
   */
  focusInput = () => {
    this.input.focus();
  };

  /**
   * Handles focus event on input
   */
  handleFocus = () => {
    this.setState({
      // hasFocus: true,
    });
  };

  /**
   * Handles change event on input
   *
   * @param {Event} event - event from input
   */
  handleChange = (event) => {
    const { target } = event;
    const value = cleanValue(target.value);

    // call external handlers before saving value and validity to state
    this.props.onChange(event, value);

    let newState = {
      // isDirty: true,
      value,
    };

    // do not mark errors instantly when input is used first time
    if (this.state.isValid === false || this.state.wasTouched) {
      newState = {
        ...newState,
        isValid: target.validity.valid,
        error: target.validationMessage,
      };
    }

    this.setState(newState);
  };

  /**
   * Handles blur event on input
   *
   * @param {Event} event - event from input
   */
  handleBlur = (event) => {
    const { target } = event;

    // call external handlers before saving value and validity to state
    this.props.onBlur(event);

    this.setState({
      wasTouched: true,
      // hasFocus: false,
      isValid: target.validity.valid,
      error: target.validationMessage,
    });
  };

  /**
   * Handles invalid event on input
   * Will get triggered when calling 'checkValidity()' via input or form
   *
   * @param {Event} event
   */
  handleInvalid = (event) => {
    event.preventDefault(); // prevent browser's error bubble

    const input = event.target;

    this.setState({
      isValid: input.validity.valid,
      error: input.validationMessage,
    });
  };

  /**
   * Extends the wrapping Theme, which is potentially provided by a wrapping ThemeProvider
   * and changes The Colors accordingly
   *
   * @param {Object} themeProp - theme from wrapping ThemeProvider.
   */
  extendThemeWithComponentTheme = (themeProp) => {
    const theme = themeProp || {};

    // TODO: Scoped Variables need Prefix for theming to work! => some colors have to be readjusted
    const defaultVariablesRangeField = {
      color: '#4A535A',
      colorValid: '#42C242',
      colorInvalid: '#FF4942',
      labelColor: '#165297',
      labelColorFocus: '#165297',
      labelColorInvalid: '#FF4942',
      borderColor: '#165297',
      borderColorHover: '#4A535A',
      borderColorFocus: '#4575AC',
      borderColorValid: '#42C242)',
      borderColorInvalid: '#FF4942',

      hintColor: '#7B8A96',
      trackColor: '#E8EEF5',
      trackColorValid: '#B3E7B3',
      trackColorInvalid: '#FFB6B3',
      trackHeight: '3px',
      trackBorderRadius: 'calc(.25em, / 2)',
      thumbSize: '1.5em',
      progressColor: '#165297',
      progressColorValid: '#42C242',
      progressColorInvalid: '#FF4942',
      thumbBackground: "#fff url('data:image/svg+xml;utf8," + ThumbHandleIcon + "') no-repeat center center",
      thumbBorderWidth: '.125em',
      thumbBorderRadius: '.3em',
    };

    return {
      ...defaultVariablesRangeField,
      ...theme,
    };
  };

  /**
   * @returns {JSX}
   */
  render() {
    const {
      id,
      props: {
        name,
        label,
        min,
        max,
        step,
        unit,
        hint,
      },
      state: {
        value,
        isRequired,
        isDisabled,
        error,
      },
    } = this;

    return (
      <ThemeProvider theme={this.extendThemeWithComponentTheme}>
        <Field>
          <Frame>
            <Label htmlFor={id}>{label}</Label>
            <Output>{formatAmount(value, unit)}</Output>
            <Input
              ref={(node) => { this.input = node; }}
              id={id}
              type="range"
              name={name}
              min={min}
              max={max}
              step={step}
              required={isRequired}
              disabled={isDisabled}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              onInput={this.handleInput}
              onChange={this.handleChange}
              onInvalid={this.handleInvalid}
              value={value}
            />
          </Frame>
          {error && (
            <FieldError htmlFor={id}>{error}</FieldError>
          )}
          {hint && (
            <FieldHint htmlFor={id}>{hint}</FieldHint>
          )}
        </Field>
      </ThemeProvider>
    );
  }

}

export default RangeField;
