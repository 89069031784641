import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { formatAmount } from '../../util/numberFormatter';
import { Container, Hint, Label, Output } from './CalculationResult.style';

class CalculationResult extends PureComponent {

  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    unit: PropTypes.string,
    hint: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number.isRequired,
  };

  static defaultProps = {
    id: `_${Math.random().toString(36).substr(2, 10)}`, // TODO use random Hash generator from UI-Kit
    unit: '',
    name: '',
    hint: '',
  };

  /**
   * @returns {JSX}
   */
  render() {
    const {
      id,
      label,
      value,
      unit,
      hint,
      name,
    } = this.props;

    return (
      <Container>
        <Label htmlFor={id}>
          {label}
          { hint && (
            <Hint htmlFor={id}>{hint}</Hint>
          )}
        </Label>
        <Output id={id} name={name}>{formatAmount(value, unit)}</Output>
      </Container>
    );
  }

}

export default CalculationResult;
