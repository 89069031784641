import Styled from 'styled-components';

export const Container = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 1.25em;
  width: 100%;
  font-family: ${props => props.theme.fontFamily};

  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

export const Label = Styled.label`
  flex: 1 1 auto;
  color: ${props => props.theme.labelColor};
  font-size: 1em;
  line-height: 1.5em;
`;

export const Hint = Styled.span`
  display: block;
  color: var(--hintColor);
  font-size: 0.75em;
  line-height: 1em;
`;

export const Output = Styled.output`
  flex: 1 0 auto;
  align-self: center;
  min-width: 4em;
  text-align: right;
  color: ${props => props.theme.labelColor};
  font-size: 1.5em;
  line-height: 2em;
`;

export default Container;
