import Styled from 'styled-components';
import RangeField from '../../molecules/RangeField/RangeField.style';
import CalculationResult from '../../molecules/CalculationResult/CalculationResult.style';
import Button from '../../atoms/Button/ffg/Button.style';
import LegalText from '../../atoms/LegalText/LegalText.style';


/* private variables */
const viewportMinWidth = '220px';
const formPadding = '.5em'; // TODO should be "1em" - this is for demo in Autobild */
const flexItemPaddingHorizontal = '.5em'; // formPadding * 0.5
const flexItemPaddingVertical = '.75em'; // formPadding * 0.75
// prevent unexpected element reordering when activating input
const controlMinWidth = `calc(${viewportMinWidth} - ${formPadding} * 2)`;


export const Form = Styled.form`
  font-size: 16px;
  line-height: 20px;
  padding: ${formPadding};

  ${LegalText} {
    margin: 2em 0 0;

    /* TODO emove - this is for demo in Autobild */
    margin: 1.5em 0 0;
    font-size: 70%;
  }
`;

export const Flex = Styled.div`
  display: flex;
  margin:
    -${flexItemPaddingVertical}
    -${flexItemPaddingHorizontal}
  ;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  /* TODO migrate Button to SC */
  ${RangeField}, ${CalculationResult}, ${Button} {
    margin:
      ${flexItemPaddingVertical}
      ${flexItemPaddingHorizontal}
    ;
    flex: 1 0 calc(50% - ${flexItemPaddingHorizontal} * 2);
    min-width: ${controlMinWidth};
  }

`;
