import Styled from 'styled-components';

export default Styled.div`
  margin-bottom: 1em;
  font-size: 0.75em;
  line-height: 1.5em;
  color: ${props => props.theme.color};

  p {
    margin: 0;
  }
`;
